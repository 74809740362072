.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border: 7px solid #1976d2; 
}

.overlay {
  background-color: rgba(255, 165, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}


.popup-row {
  display: flex;
}

.popup-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
}

.popup-item label {
  margin-bottom: 5px;
}

.popup-item input {
  width: 130px; /* テキストボックスの幅を調整 */
  padding: 5px;
}
